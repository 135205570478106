<template>
  <CContainer class="login">
    <CRow class="login__content justify-content-center align-items-center text-center">
      <CCol sm="12" md="7" lg="5" xl="4">
        <div class="mb-3">
          <img src="@/assets/images/alert-triangle.svg">
        </div>
        <h2 v-translate translate-context="service.maintenance" style="line-height: normal">
          Service temporarily under maintenance</h2>
        <p v-translate translate-context="service.maintenance">
          We apologize for the inconvenience, please try again later
        </p>
      </CCol>
    </CRow>
    <TheFooter/>
  </CContainer>
</template>

<script>
import TheFooter from "@/containers/TheFooter.vue";

export default {
  name: 'Maintenance',
  components: {TheFooter},

}
</script>
